"use client";

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import GSInfluencersImg1 from "../../../../public/images/new/GS-Influencers-Img-1.png";
import GSInfluencersImg2 from "../../../../public/images/new/GS-Influencers-Img-2.png";
import GSInfluencersImg3 from "../../../../public/images/new/GS-Influencers-Img-3.png";
import GSInfluencersImg4 from "../../../../public/images/new/GS-Influencers-Img-4.png";
import Image from "next/image";
import GSTexture from "../../../../public/images/new/GS-Texture.png";
interface Props {
  bigText: string;
  smallText: string;
  cards: {
    id: string;
    idx: number;
    description: string;
    name: string;
    designation: string;
    image: Image;
  }[];
}
export default function SectionInfluencers(props: Props) {
  const [activeCard, setActiveCard] = useState(props.cards[0].idx);
  const handleButtonClick = (idx: number) => {
    setActiveCard(idx);
  };
  useEffect(() => {
    let animationFrameId: number;
    let lastUpdateTime = Date.now();
    const animate = () => {
      const now = Date.now();
      const elapsedTime = now - lastUpdateTime;
      if (elapsedTime >= 5000) {
        // 5 seconds
        setActiveCard(prevActiveCardId => {
          const currentIndex = props.cards.findIndex(card => card.idx === prevActiveCardId);
          const nextIndex = (currentIndex + 1) % props.cards.length;
          return props.cards[nextIndex].idx;
        });
        lastUpdateTime = now;
      }
      animationFrameId = requestAnimationFrame(animate);
    };
    animate(); // Start the animation loop

    return () => {
      cancelAnimationFrame(animationFrameId); // Cleanup the animation frame to prevent memory leaks
    };
  }, []);
  const buttonData = props.cards.map((card, index) => ({
    id: card.id,
    idx: card.idx,
    imgSrc: card.image.src,
    alt: card.image.alt,
    index: index
  }));
  const buttonsRepeatedData = buttonData.concat(buttonData);
  return <div className="section__influencers" data-sentry-component="SectionInfluencers" data-sentry-source-file="SectionInfluencers.tsx">
      <div className="section__influencers-container section__container--small">
        <div className="section__influencers-text">
          <p>{props.smallText}</p>
          <h2>{props.bigText}</h2>
        </div>
        <div className="section__influencers-slider">
          <div className="section__influencers-slider-container">
            <div className="section__influencers-slider-texture">
              <Image src={GSTexture} alt="Texture" width={300} height={300} loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionInfluencers.tsx" />
            </div>
            {props.cards.map((card, index) => <motion.div className={`section__influencers-slider-card`} initial={{
            opacity: 0
          }} animate={{
            opacity: card.idx === activeCard ? 1 : 0
          }} transition={{
            duration: 0.5
          }} key={index}>
                <div className="section__influencers-slider-img">
                  <motion.div initial={{
                opacity: 0
              }} animate={{
                x: card.idx === activeCard ? "0%" : card.idx > activeCard ? "20%" : "-20%",
                opacity: card.idx === activeCard ? 1 : card.idx > activeCard ? 0 : 0
              }} transition={{
                duration: 0.5
              }}>
                    <Image src={card.image.src} alt={card.image.alt} width={200} height={200} loading='lazy' />
                  </motion.div>
                  <span></span>
                </div>
                <div className="section__influencers-slider-text">
                  <blockquote>
                    <div className="section__influencers-slider-text-quotes">
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 35 25" fill="none">
                        <path opacity="0.1" d="M6.92222 25C4.71852 25 3.08148 24.5211 2.01111 23.5632C1.0037 22.5415 0.5 21.1367 0.5 19.3487V17.3372C0.5 14.3359 1.28704 11.2708 2.86111 8.14176C4.43519 5.01277 6.54444 2.29885 9.18889 0H15.9889C14.163 1.97956 12.6204 3.89527 11.3611 5.74713C10.1648 7.59898 9.28333 9.67433 8.71667 11.9732C11.8019 12.5479 13.3444 14.3359 13.3444 17.3372V19.3487C13.3444 21.1367 12.8093 22.5415 11.7389 23.5632C10.7315 24.5211 9.12593 25 6.92222 25ZM25.4333 25C23.2296 25 21.5926 24.5211 20.5222 23.5632C19.5148 22.5415 19.0111 21.1367 19.0111 19.3487V17.3372C19.0111 14.3359 19.7981 11.2708 21.3722 8.14176C22.9463 5.01277 25.0556 2.29885 27.7 0H34.5C32.6741 1.97956 31.1315 3.89527 29.8722 5.74713C28.6759 7.59898 27.7944 9.67433 27.2278 11.9732C30.313 12.5479 31.8556 14.3359 31.8556 17.3372V19.3487C31.8556 21.1367 31.3204 22.5415 30.25 23.5632C29.2426 24.5211 27.637 25 25.4333 25Z" fill="white" />
                      </svg>
                    </div>
                    {card.description}
                  </blockquote>
                  <div className="section__influencers-slider-text-author">
                    <p>{card.name}</p>
                    <span>{card.designation}</span>
                  </div>
                </div>
              </motion.div>)}
            <div className="section__influencers-slider-buttons">
              <motion.div animate={{
              x: activeCard * -46
            }} transition={{
              duration: 0.5
            }} className="section__influencers-slider-buttons-slider" data-sentry-element="unknown" data-sentry-source-file="SectionInfluencers.tsx">
                {buttonsRepeatedData.map((button, index) => <motion.div key={index} onClick={() => handleButtonClick(buttonData[button.index].idx)} style={{
                pointerEvents: index + 1 <= activeCard || index - activeCard > 2 ? "none" : "auto"
              }} animate={{
                opacity: index + 1 <= activeCard || index - activeCard > 2 ? 0 : 1
              }} transition={{
                duration: 0.5
              }} className={`section__influencers-slider-div`}>
                    <div className="section__influencers-slider-div-container">
                      <span></span>
                      <Image src={props.cards[button.index].image.src} width={40} height={40} loading='lazy' alt={props.cards[button.index].image.alt ?? "Influencers Image"} className="section__influencers-slider-div-img" />
                    </div>
                  </motion.div>)}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>;
}
import React from "react";
const HomePageSkeleton: React.FC = () => {
  return <div className="m-4 md:mx-[200px] space-y-8 animate-pulse" data-sentry-component="HomePageSkeleton" data-sentry-source-file="skeleton.tsx">
      {/* Hero Section */}
      {/* Hero Section */}
      <div className="min-h-96 space-y-8">
        <div className="w-full p-4 bg-gray-200 rounded-lg space-y-4 animate-pulse">
          <div className="h-16 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg"></div>
          <div className="h-20 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg"></div>
          <div className="h-6 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg"></div>
          <div className="h-32 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-lg"></div>
        </div>

        {/* Marquee Section */}
        <div className="flex flex-col min-h-72 space-y-4 overflow-hidden md:flex-row md:space-y-0 md:space-x-8">
          <div className="flex flex-col justify-center w-full p-4 space-y-4 bg-gray-200 rounded md:w-3/4">
            <div className="w-5/6 h-6 bg-gray-300 rounded"></div>
            <div className="w-2/3 h-6 bg-gray-300 rounded"></div>
            <div className="flex space-x-4">
              <div className="w-1/3 h-12 bg-gray-300 rounded"></div>
              <div className="w-1/3 h-12 bg-gray-300 rounded"></div>
              <div className="w-1/3 h-12 bg-gray-300 rounded"></div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-center w-full p-4 bg-gray-200 rounded md:w-1/4">
            <div className="w-2/3 h-20 mb-4 bg-gray-300 rounded"></div>
            <div className="w-2/3 h-6 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>

      {/* Programs Section */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {[...Array(6)].map((_, index) => <div key={index} className="h-64 bg-gray-200 rounded"></div>)}
      </div>

      {/* CTA Card */}
      <div className="w-full h-40 bg-gray-200 rounded"></div>

      {/* Advantages Section */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="h-40 bg-gray-200 rounded"></div>
        <div className="h-40 bg-gray-200 rounded"></div>
      </div>

      {/* Learners Section */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {[...Array(3)].map((_, index) => <div key={index} className="h-48 bg-gray-200 rounded"></div>)}
      </div>

      {/* Alumni Section */}
      <div className="flex flex-wrap justify-center gap-4">
        {[...Array(8)].map((_, index) => <div key={index} className="w-32 h-16 bg-gray-200 rounded"></div>)}
      </div>

      {/* Testimonials Section */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {[...Array(4)].map((_, index) => <div key={index} className="h-40 bg-gray-200 rounded"></div>)}
      </div>

      {/* Upskill Section */}
      <div className="w-full h-64 bg-gray-200 rounded"></div>

      {/* Tabs Section */}
      <div className="space-y-4">
        <div className="flex space-x-2">
          {[...Array(4)].map((_, index) => <div key={index} className="w-24 h-8 bg-gray-200 rounded"></div>)}
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {[...Array(8)].map((_, index) => <div key={index} className="h-48 bg-gray-200 rounded"></div>)}
        </div>
      </div>

      {/* Influencers Section */}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {[...Array(2)].map((_, index) => <div key={index} className="h-64 bg-gray-200 rounded"></div>)}
      </div>

      {/* Investors Section */}
      <div className="w-full h-64 bg-gray-200 rounded"></div>
    </div>;
};
export default HomePageSkeleton;
"use client";

import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useInView } from "framer-motion";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import GSGrid from "../../../../public/images/new/GS-Grid.png";
import GSTexture from "../../../../public/images/new/GS-Texture.png";
interface Props {
  title: string;
  description: string;
  button: Button;
  textScroll: string[];
}
export default function SectionHero(props: Props) {
  const [hovered, setHovered] = useState(false);
  const pathName = usePathname();
  const sectionRef = useRef(null);
  const sectionInView = useInView(sectionRef, {
    amount: 0
  });
  useEffect(() => {
    if (sectionInView) {
      document.querySelector(".section__navbar")?.classList.remove("section__navbar--colored");
    } else {
      document.querySelector(".section__navbar")?.classList.add("section__navbar--colored");
    }
  }, [sectionInView]);
  const handleClick = (id: string) => {
    const element = document.getElementById(id);
    const offset = 0;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element?.getBoundingClientRect().top ?? 0;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
    if (typeof window !== "undefined" && typeof window.gtag === "function") {
      window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
        event_label: "Click event on Explore programs",
        page_path: `${process.env.NEXT_PUBLIC_APP_URL}${pathName}`
      });
    }
  };
  return <div className="section__hero" ref={sectionRef} data-sentry-component="SectionHero" data-sentry-source-file="SectionHero.tsx">
      <div className="section__hero-container section__container">
        <div className="section__hero-banner">
          <Image className="section__hero-banner-texture" src={GSTexture} alt="texture" loading="eager" data-sentry-element="Image" data-sentry-source-file="SectionHero.tsx" />
          <Image className="section__hero-banner-grid" src={GSGrid} alt="grid" loading="eager" data-sentry-element="Image" data-sentry-source-file="SectionHero.tsx" />
          <div className="section__hero-banner-text">
            <h1>{props.title}</h1>
            <p>{props.description}</p>
            <button onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onClick={() => handleClick("section--programs")}>
              {props.button.text}
              {!hovered ? <div className="section__hero-banner-svg" style={{
              position: "absolute",
              right: "4px"
            }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 12 13" fill="none">
                    <path d="M1 7.11108L6 12L11 7.11108" stroke="#002726" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 1L6 5.88889L11 1" stroke="#002726" strokeOpacity="0.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div> : <DotLottieReact src="/images/new/GS-Down-Arrow.lottie" loop autoplay speed={1.5} style={{
              width: "40px",
              height: "40px",
              position: "absolute",
              right: "4px"
            }} />}
            </button>
          </div>

          <div className="section__hero-banner-img">
            <Image src="/images/new/Fader_top.svg" alt="" width={416} height={68} className="blend-top" loading="lazy" data-sentry-element="Image" data-sentry-source-file="SectionHero.tsx" />
            <DotLottieReact src="/images/new/bg.lottie" loop autoplay style={{
            width: "348px",
            height: "380px",
            maskImage: "linear-gradient(var(--mask-direction, to bottom), hsla(0, 0%, 0%, 0), hsl(0, 0%, 0%) 40%, hsl(0, 0%, 0%) 60%, hsla(0, 0%, 0%, 0))"
          }} data-sentry-element="DotLottieReact" data-sentry-source-file="SectionHero.tsx" />
            <Image src="/images/new/Fader_bottom.svg" alt="" width={416} height={68} className="blend-bottom" loading="lazy" data-sentry-element="Image" data-sentry-source-file="SectionHero.tsx" />
          </div>
        </div>
      </div>
    </div>;
}
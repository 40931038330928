"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import GSTexture from "../../../../public/images/new/GS-Texture.png";
import Image from "next/image";
interface Props {
  smallText: string;
  bigText: string;
  slider: {
    smallText: string;
    bigText: string;
    image: Image;
  }[];
}
export default function SectionAdvantages(props: Props) {
  return <div className="section__advantages" data-sentry-component="SectionAdvantages" data-sentry-source-file="SectionAdvantages.tsx">
      <div className="section__advantages-container">
        <div className="section__advantages-header section__container--small">
          <p>{props?.smallText}</p>
          <h2 className="heading__secondary">{props?.bigText}</h2>
        </div>
        <SliderAdvantages slider={props?.slider} data-sentry-element="SliderAdvantages" data-sentry-source-file="SectionAdvantages.tsx" />
      </div>
    </div>;
}
function SliderAdvantages({
  slider
}: {
  slider: Props["slider"];
}) {
  return <div className="section__advantages-slider-container" data-sentry-component="SliderAdvantages" data-sentry-source-file="SectionAdvantages.tsx">
      <Swiper className="section__advantages-slider-swiper" modules={[Navigation, Mousewheel]} slidesPerView={"auto"} spaceBetween={38} mousewheel={{
      eventsTarget: ".section__advantages-slider-swiper",
      forceToAxis: true
    }} breakpoints={{
      0: {
        spaceBetween: 10
      },
      768: {
        spaceBetween: 20
      },
      1024: {
        spaceBetween: 30
      }
    }} data-sentry-element="Swiper" data-sentry-source-file="SectionAdvantages.tsx">
        {slider?.map((item, index) => <SwiperSlide key={`slide-${index}`}>
            <SlideAdvantages index={index} slider={item} />
          </SwiperSlide>)}
      </Swiper>
    </div>;
}
function SlideAdvantages({
  slider,
  index
}: {
  slider: Props["slider"][number];
  index: number;
}) {
  return <div className="section__advantages-slider-slide" data-sentry-component="SlideAdvantages" data-sentry-source-file="SectionAdvantages.tsx">
      <Image src={GSTexture} alt="texture" className="section__advantages-slider-slide-texture" loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionAdvantages.tsx" />
      <span style={{
      backgroundImage: `linear-gradient(to bottom, rgba(0, 39, 38, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 39, 38, ${index === 0 ? 1 : 0}) 100%)`
    }}></span>
      <div className="section__advantages-slider-slide-img">
        <Image src={slider.image.src} alt={slider.image.alt} width="398" height="598" className="section__advantages-slider-slide-img" loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionAdvantages.tsx" />
      </div>
      <div className="section__advantages-slider-slide-text">
        <h4>{slider.bigText}</h4>
        <p>{slider.smallText}</p>
      </div>
    </div>;
}
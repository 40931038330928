import Image from "next/image";
import GSDots from "../../../../public/images/new/GS-Dots.svg";
import { formatNumber, imageLoader } from "@/utils/strapiTojs";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Counter from "../Common/Counter";
import AnimatedLastDigit from "../Common/Counter";
interface Props {
  studentCount: number;
  studentText: string;
  studentDescription: string;
  students: {
    studentImage: Image;
    companyImage: Image;
  }[];
  trustCards: {
    smallText: string;
    bigText: string;
    logo: Image;
  }[];
}
export function SectionMarquee(props: Props) {
  return <div className="section__marquee" data-sentry-component="SectionMarquee" data-sentry-source-file="SectionMarquee.tsx">
      <div className="section__marquee-container section__container">
        <SectionMarqueeLearners {...props} data-sentry-element="SectionMarqueeLearners" data-sentry-source-file="SectionMarquee.tsx" />
        <div className="section__hero-brands">
          <DotLottieReact src="/images/new/GS-Brands-updated.lottie" loop autoplay className="section__hero-brands-lottie" data-sentry-element="DotLottieReact" data-sentry-source-file="SectionMarquee.tsx" />
        </div>
      </div>
    </div>;
}
export function SectionMarqueeLearners(props: Props) {
  const repeatedStudentsImages = [...props.students, ...props.students, ...props.students, ...props.students, ...props.students, ...props.students, ...props.students, ...props.students];
  return <div className="section__hero-lerners" data-sentry-component="SectionMarqueeLearners" data-sentry-source-file="SectionMarquee.tsx">
      <Image src={GSDots} className="section__hero-lerners-dots" alt="dots" loading='lazy' data-sentry-element="Image" data-sentry-source-file="SectionMarquee.tsx" />
      <div className="section__hero-lerners-text">
        <h2>
          <Counter targetValue={formatNumber(props.studentCount).number} data-sentry-element="Counter" data-sentry-source-file="SectionMarquee.tsx" />
          {/* {formatNumber(props.studentCount).number} */}
          {formatNumber(props.studentCount).shortHand}+ {props.studentText}
        </h2>
        <p>{props.studentDescription}</p>
      </div>
      <div className="section__hero-lerners-marquee">
        <div className="section__hero-lerners-marquee-slider">
          {repeatedStudentsImages.map((student, index) => <div key={index} className="section__hero-lerners-marquee-card">
              <div className="section__hero-lerners-marquee-card-img">
                <Image src={student.studentImage.src} width={86} height={86} loading='lazy' alt={student.studentImage.alt || "Learner working at"} />
              </div>
              <div className="section__hero-lerners-marquee-card-brand">
                <Image src={student.companyImage.src} width={88} height={108} loading='lazy' alt={student.companyImage.alt || "Brand Name"} />
              </div>
            </div>)}
        </div>
      </div>
    </div>;
}
import mixpanel from "mixpanel-browser";

export interface EnrollmentResponseType {
  programId: number;
  id: number;
  cohortCode: string;
  cohortStatus: string;
  programName: string;
  startDate: string;
  endDate: string;
  programType: string;
}

if (process.env.NEXT_PUBLIC_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.NEXT_PUBLIC_APP_MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
  });
}

const eventTracker = {
  homePagePageViewed: ({ pageName }: { pageName: string }) => {
    mixpanel.track("Page Viewed", {
      page_name: pageName,
    });
  },
  freeCoursePageViewed: ({ pageName }: { pageName: string }) => {
    mixpanel.track("Free Course Page Viewed", {
      page_name: pageName,
    });
  },

  freeCourseCardClicked: ({
    courseName,
    courseId,
    mentorName,
    courseDifficulty,
  }: {
    courseName: string;
    courseId: number;
    mentorName: string;
    courseDifficulty?: string;
  }) => {
    mixpanel.track("Free Course Card Clicked", {
      course_id: courseId,
      course_name: courseName,
      mentor_name: mentorName,
      course_difficulty: courseDifficulty,
    });
  },

  premiumCourseCardClicked: ({
    courseName,
    courseId,
    mentorName,
    applicationLink,
  }: {
    courseName: string;
    courseId: number;
    mentorName: string;
    applicationLink?: string;
  }) => {
    mixpanel.track("Premium Course Card Clicked", {
      course_id: courseId,
      course_name: courseName,
      mentor_name: mentorName,
      application_link: applicationLink,
    });
  },

  freeCourseCTAClicked: ({
    courseName,
    courseId,
    webinarId,
    mentorName,
    courseDifficulty,
  }: {
    courseName: string;
    courseId: number;
    webinarId: number;
    mentorName: string;
    courseDifficulty: string;
  }) => {
    mixpanel.track("Free Course CTA Clicked", {
      course_id: courseId,
      course_name: courseName,
      webinar_id: webinarId,
      mentor_name: mentorName,
      course_difficulty: courseDifficulty,
    });
  },

  freeCourseFiltered: ({
    filterType,
    filteredBy,
  }: {
    filterType: string;
    filteredBy?: string;
  }) => {
    mixpanel.track("Free Course Filtered", {
      filter_type: filterType,
      filter_by: filteredBy,
    });
  },

  freeCoursePageDetailsViewed: ({
    courseId,
    courseName,
    webinarId,
    courseDifficulty,
    webianrSlot,
  }: {
    courseName: string;
    courseId: number;
    webinarId: number;
    courseDifficulty: string;
    webianrSlot?: string;
  }) => {
    mixpanel.track("Free Course Page Details Viewed", {
      course_name: courseName,
      course_id: courseId,
      webinar_id: webinarId,
      course_difficulty: courseDifficulty,
      webianr_slot: webianrSlot,
    });
  },

  slPageViewed: ({
    pageName,
    pageURL,
    programType,
  }: {
    pageName: string;
    pageURL: string;
    programType: string;
  }) => {
    mixpanel.track("Page Viewed", {
      page_name: pageName,
      page_url: pageURL,
      program_type: programType,
    });
  },
};

export default eventTracker;

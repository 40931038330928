export const API_ENDPOINTS = {
  APP_SERVER_URL: process.env.NEXT_PUBLIC_APP_SERVER_URL,
  // NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
  dummyUrl: (id: number) => '/v1/users/${id}',
  freeCourse: (courseId: number) => `/v1/free-courses/${courseId}?populate=*`,
  allIndustries: () => '/v1/industries?populate[subIndustries][fields][0]=subIndustry&fields[0]=industryName&publicationState=live',
  allSubIndustries: () => '/v1/sub-industries?filters[freeCourses][courseName][$notNull]=true&populate[industries][fields][0]=industryName&fields[0]=subIndustry&pagination[pageSize]=50&pagination[page]=1&publicationState=live',
  allCourses: () => '/v1/free-courses?populate[subIndustries][fields][0]=subIndustry&populate[mentors][fields][0]=name&populate[mentors][fields][1]=designation&populate[mentors][fields][2]=companyName&populate[mentors][fields][3]=mentorImageUrl&populate[industries][fields][0]=industryName&populate[courseCurriculum][fields][0]=title&fields[0]=courseName&fields[1]=courseDescription&fields[2]=courseRating&fields[3]=courseDifficulty&fields[4]=webinarId&fields[5]=isPremium&fields[6]=thumbnailText&pagination[pageSize]=100&pagination[page]=1&publicationState=live',
  industryPageData: (industry: string, pageNo:number, courseDifficulty?:string, pageSize?:number) => 
  `/v1/free-courses?${courseDifficulty ? `filters[courseDifficulty][$eq]=${courseDifficulty}&` : ``}filters[industries][industryName][$eq]=${industry}&populate[subIndustries][fields][0]=subIndustry&populate[mentors][fields][0]=name&populate[mentors][fields][1]=designation&populate[mentors][fields][2]=companyName&populate[mentors][fields][3]=mentorImageUrl&populate[industries][fields][0]=industryName&populate[courseCurriculum][fields][0]=title&populate[meta][fields][0]=pageCount&fields[0]=courseName&fields[1]=courseDescription&fields[2]=courseRating&fields[3]=courseDifficulty&fields[4]=isPremium&fields[5]=premiumCourseDuration&fields[6]=premiumCardLink&fields[7]=thumbnailText&pagination[pageSize]=${pageSize ? pageSize : 30}&pagination[page]=${pageNo}&publicationState=live&locale[0]=en`,
  subIndustryPageData: (subIndustry: string, pageNo:number, courseDifficulty?:string) => `/v1/free-courses?${courseDifficulty ? `filters[courseDifficulty][$eq]=${courseDifficulty}&` : ``}filters[subIndustries][subIndustry][$eq]=${subIndustry}&filters[subIndustries][publishedAt][$notNull]=true&populate[subIndustries][fields][0]=subIndustry&populate[mentors][fields][0]=name&populate[mentors][fields][1]=designation&populate[mentors][fields][2]=companyName&populate[mentors][fields][3]=mentorImageUrl&populate[industries][fields][0]=industryName&populate[courseCurriculum][fields][0]=title&fields[0]=courseName&fields[1]=courseDescription&fields[2]=courseRating&fields[3]=courseDifficulty&fields[4]=isPremium&fields[5]=premiumCourseDuration&fields[6]=premiumCardLink&fields[7]=thumbnailText&pagination[pageSize]=30&pagination[page]=${pageNo}&publicationState=live`,
  getTestimonials: (pageSize?: number) => `/v1/testimonials?filters[testimonial][$notNull]=true&populate=userImage${pageSize ? `&pagination[pageSize]=${pageSize}&pagination[page]=1` : ``}`,
  getSubIndustryFilters: (industry: string) => `v1/sub-industries?filters[industries][industryName][$eq]=${industry}&populate[industries][fields][0]=industryName&fields[0]=subIndustry&publicationState=live`,
  getPremiumCourse: () => '/v1/free-courses?filters[isPremium][$eq]=true&populate[mentors][fields][0]=name&populate[mentors][fields][1]=designation&populate[mentors][fields][2]=companyName&populate[mentors][fields][3]=mentorImageUrl&fields[0]=courseName&fields[1]=courseDescription&fields[2]=thumbnailUrl&publicationState=live',
  NextSchedule: (courseId: number) => `/v2/webinarSchedules/${courseId}/upcoming`,
  getTransitions: (pageSize?: number) => `/v1/testimonials?filters[prevDesignation][$notNull]=true&populate=userImage${pageSize ? `&pagination[pageSize]=${pageSize}&pagination[page]=1` : ``}`,
  getVideoTestimonials: (pageSize?: number) => `/v1/testimonials?filters[testimonial][$null]=true&filters[prevCompany][$null]=true&populate=videoTestimonial${pageSize ? `&pagination[pageSize]=${pageSize}&pagination[page]=1` : ``}`,
  getPrimaryIndustry: (subIndustry: string) => `/v1/sub-industries?filters[subIndustry][$eq]=${subIndustry}&populate[industries][fields][0]=industryName&populate[primaryIndustry][fields][0]=industryName&fields[0]=subIndustry&pagination[pageSize]=100&pagination[page]=1&publicationState=live`,
  getAllThumbnails: () => '/v1/upload/files',
  getCoursesbyIndustry: (industryId: number) => `/v1/free-courses?filters[industries][id][$eq]=${industryId}&populate[subIndustries][fields][0]=subIndustry&populate[industries][fields][0]=industryName&populate[mentors][fields][0]=name&populate[mentors][fields][1]=designation&populate[mentors][fields][2]=companyName&populate[mentors][fields][3]=mentorImageUrl&populate[courseCurriculum][fields][0]=title&fields[0]=courseName&fields[1]=courseDescription&fields[2]=courseRating&fields[3]=courseDifficulty&fields[4]=webinarId&fields[5]=isPremium&fields[6]=thumbnailText&pagination[pageSize]=30&pagination[page]=1&publicationState=live&locale[0]=en`,
  subIndustryFaqs: (subIndustry: string) => `/v1/sub-industries?filters[subIndustry][$eq]=${subIndustry}&populate[sectionalFaq][populate]=*&populate[industries][fields][0]=industryName&populate[primaryIndustry][fields][0]=industryName&pagination[pageSize]=10&pagination[page]=1&publicationState=live&locale[0]=en`,
  industryFaqs: (industry:string) => `/v1/industries?filters[industryName][$eq]=${industry}&populate[subIndustries][fields][0]=subIndustry&populate[sectionalFaq][populate]=*&pagination[pageSize]=10&pagination[page]=1&publicationState=live`,
  freeCertificateCourses: (industry: string) => `/v1/industries?filters[industryName][$eq]=${industry}&populate[subIndustries][fields][0]=subIndustry&fields[0]=industryName&pagination[pageSize]=40&pagination[page]=1&publicationState=live&locale[0]=en`,
  freeTopics: (industry: string) => `/v1/topics?filters[industry][industryName][$eq]=${industry}&fields[0]=topicName&pagination[pageSize]=40&pagination[page]=1&publicationState=live&locale[0]=en`,
  //SITEMAP-APIs
  courseData: (pageNo: number) => `/v1/free-courses?populate[industries][fields][0]=industryName&fields[0]=courseName&pagination[pageSize]=400&pagination[page]=${pageNo}&publicationState=live`,
  industryData: (pageNo: number) => `/v1/industries?pagination[pageSize]=20&pagination[page]=${pageNo}&publicationState=live`,
  subIndustryData: (pageNo: number) => `/v1/sub-industries?pagination[pageSize]=20&pagination[page]=${pageNo}&publicationState=live`,
  getAllArticles: (pageNo: number) => `/v1/articles?populate=*&pagination[pageSize]=10&pagination[page]=${pageNo}&publicationState=live&locale[0]=en`,

  //Topics-APIS
  getTopics: (pageNo: number) => `/v1/topics?populate[industry][fields][0]=industryName&populate[topicSectionalFaq][populate]=*&populate[subTopics][populate][0]=articles&populate[subTopics][populate][1]=articles.reviewedBy&populate[subTopics][populate][2]=articles.mentors&populate[subTopics][populate][3]=subTopicfaq&populate[subTopics][populate][4]=articles.articleFaq&populate[subTopics][populate][5]=articles.thumbnail&fields[0]=topicName&fields[1]=description&pagination[pageSize]=10&pagination[page]=${pageNo}&publicationState=live&locale[0]=en`,
  getTopicContent: (topicId: number) => `/v1/topics?filters[id][$eq]=${topicId}&populate[industry][fields][0]=industryName&populate[topicSectionalFaq][populate]=*&populate[subTopics][populate][0]=articles&populate[subTopics][populate][1]=articles.reviewedBy&populate[subTopics][populate][2]=articles.mentors&populate[subTopics][populate][3]=subTopicfaq&populate[subTopics][populate][4]=articles.articleFaq&populate[subTopics][populate][5]=articles.thumbnail&fields[0]=topicName&fields[1]=description&pagination[pageSize]=10&pagination[page]=1&publicationState=live&locale[0]=en`, 
  getSubTopicDetail: (subTopicId: number) => `/v1/sub-topics?filters[id][$eq]=${subTopicId}&populate=*`,
  getArticle: (articleId: number) => `/v1/articles?filters[id][$eq]=${articleId}&populate=*`, 
  getPremiumTopics: () => '/v1/free-courses?filters[isPremium][$eq]=true&populate[industries][fields][0]=industryName&populate[mentors][fields][0]=name&populate[mentors][fields][1]=designation&populate[mentors][fields][2]=companyName&populate[mentors][fields][3]=mentorImageUrl&fields[0]=premiumCardLink&fields[1]=courseName&fields[2]=courseDescription&fields[3]=premiumCourseDuration&pagination[pageSize]=20&pagination[page]=1&publicationState=live' ,

};

export const LEARNERS_ENDPOINTS = {
  SERVER_URL: process.env.NEXT_PUBLIC_LEARNERS_URL,
  checkLogin: '/v1/check-login',
  getPrograms: '/v1/discovery',
  getPerks : '/v1/perks',
  getAllInstructors:'/v1/discovery/all-instructors',
  getInstructors: '/v1/discovery/all-instructors',
  getIndustryData: (industryId: number) => `/v1/discovery/industry/${industryId}`,
  getSubIndustryData: (subIndustryId: number) => `/v1/discovery/sub-industry/${subIndustryId}`,
  getSubIndustryCount: (industryId: number) => `/v1/discovery/industries-programs/${industryId}`,
};

export const STRAPI_ENDPOINTS = {
  SERVER_URL: process.env.NEXT_PUBLIC_APP_SERVER_URL,
  getIndustriesData: '/v1/premium-industries?sort[0]=jobs_in_market_curr_year:desc&pagination[limit]=-1&populate[premium_subindustries][pagination][limit]=-1&populate[premium_subindustries][populate][standard_lps][pagination][limit]=-1&populate[premium_subindustries][populate][standard_lps][populate][premium_mentors][pagination][limit]=-1&populate[premium_subindustries][populate][standard_lps][populate][premium_mentors][populate][0]=mentorImage&populate[premium_subindustries][populate][standard_lps][populate][banner_image]=true&populate[premium_subindustries][populate][standard_lps][populate][standard_lp_bonuses]=true',
  getMentorsData: '/v1/premium-mentors?populate=mentorImage&pagination[limit]=-1',
}

function fetchWithTimeout(url: string, options = {}, timeout = 5000) {
  const controller = new AbortController();
  const { signal } = controller;

  const fetchPromise = fetch(url, { ...options, signal });

  const timeoutId = setTimeout(() => controller.abort(), timeout);

  return fetchPromise
    .finally(() => clearTimeout(timeoutId))
    .catch(error => {
      if (error.name === 'AbortError') {
        throw new Error('Fetch request timed out');
      }
      throw error;
    });
}

export const apiService = (url: string, isToken?: boolean, options?: any, timeout?: number) => {
  return fetchWithTimeout(url,
    {
      headers: isToken ? {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_APP_TOKEN}`
      } : {},
      ...options
    }, timeout);
}

export const strapiApiService = (url: string, isToken?: boolean) => {
  return fetchWithTimeout(url,
    {
      headers: isToken ? {
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`
      } : {}
    });
}

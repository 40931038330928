"use client";

import React, { useEffect, useMemo, useState } from "react"; // Removed 'use' as it's not a valid import from React
import Image from "next/image";
import { motion } from "framer-motion";
interface Props {
  bigText: string;
  smallText: string;
  subTitle: string;
  companyLogos: {
    logo: Image;
  }[];
  growthTeamImages: {
    image: Image;
  }[];
}
export default function SectionInvestors(props: Props) {
  const totalInvestors = useMemo(() => props.growthTeamImages.map(image => ({
    src: image.image,
    alt: image.image.alt
  })), [props.growthTeamImages]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [investors, setInvestors] = useState(totalInvestors.slice(0, 12));
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => {
        let tmpIndex = prevIndex + 12;

        //if tmpIndex is greater than or equal to length of array that means we need to reset the index to 0
        if (tmpIndex >= totalInvestors.length) {
          setInvestors(totalInvestors.slice(0, 12));
          return 0;
        }
        const newIndex = tmpIndex % totalInvestors.length;
        setInvestors(totalInvestors.slice(newIndex, newIndex + 12));
        return newIndex;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  const MotionImage = motion(Image);
  return <div className="section__investors section__bg" data-sentry-component="SectionInvestors" data-sentry-source-file="SectionInvestors.tsx">
      <div className="section__investors-container section__container--small">
        <div className="section__investors-header">
          <p>
            <span>{props.smallText}</span>
          </p>
          <h2 className="heading__secondary">{props.bigText}</h2>
          <p>{props.subTitle}</p>
        </div>
        <div className="section__investors-content">
          <div className="section__investors-content-brands">
            {props.companyLogos.map((item, index) => <div className="section__investors-content-brand" key={index}>
                <Image key={index} src={item.logo.src} alt={item.logo.alt} width={200} height={200} loading="lazy" />
              </div>)}
          </div>
          <div className="section__investors-content-divider"></div>
          <div className="section__investors-content-grid">
            {investors.map((val, index) => <div key={index} className="section__investors-content-grid-item">
                <MotionImage initial={{
              opacity: 0
            }} animate={{
              opacity: 1
            }} exit={{
              opacity: 0
            }} transition={{
              duration: 1.4,
              delay: index * 0.06
            }} src={val.src.src} alt={val.alt} width={200} height={200} />
              </div>)}
            <button className="section__investors-content-grid-add-more section__investors-content-grid-add-more-non-clickable">
              and more...
            </button>
          </div>
        </div>
      </div>
    </div>;
}
"use client";

import { Industry, Program, StandardLP } from "@/services/types";
import { useEffect, useState } from "react";
import React from "react";
import SliderMentorship from "../Common/SliderMentorship";
import SliderSelfPaced from "../Common/SliderSelfPaced";
import { useQuery } from "@tanstack/react-query";
import { fetchIndustriesData } from "@/services/external";
function SectionPrograms({
  learnerData
}: {
  learnerData: Program[] | undefined;
}) {
  const {
    data: industries
  } = useQuery<Industry[]>({
    queryKey: ["industries"],
    queryFn: () => fetchIndustriesData()
  });
  const [programs, setPrograms] = useState<{
    [key: number]: StandardLP[];
  } | undefined>();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(-1);
  let firstFilterSet = false;
  useEffect(() => {
    if (industries) {
      // Map industries to programs
      let temp: {
        [key: number]: StandardLP[];
      } = {};
      for (let industry of industries) {
        temp[industry.id] = [];
        let subindustries = industry.attributes.premium_subindustries.data;
        if (!subindustries) continue;
        for (let subindustry of subindustries) {
          let standard_lps = subindustry.attributes.standard_lps.data;
          if (!standard_lps) continue;
          for (let program of standard_lps) {
            temp[industry.id].push(program);
          }
        }
      }

      // Remove duplicate programs from each industry
      for (let industryId in temp) {
        const seenProductIds = new Set();
        temp[industryId] = temp[industryId].filter(program => !seenProductIds.has(program.attributes.productId) && seenProductIds.add(program.attributes.productId));
      }
      setPrograms(temp);
    }
  }, [industries]);
  const [discoveryPrograms, setDiscoveryPrograms] = useState<{
    [key: number]: Program;
  } | undefined>();
  useEffect(() => {
    if (learnerData && learnerData.length > 0) {
      // Map productIds to discovery programs, to easily fetch discovery program data of a standardlp
      let temp: {
        [key: number]: Program;
      } = {};
      for (let program of learnerData) {
        temp[program.product_id] = program;
      }
      setDiscoveryPrograms(temp);
    }
  }, [learnerData]);
  const mentorshipTypes = ["Intensive Program", "Career Accelerator Program"];
  const selfPacedTypes = ["OnDemand"];
  const filterByProgramTypes = (programTypes: any, index: number) => {
    return programs && discoveryPrograms && programs[index] ? programs[index].filter(item => programTypes.includes(discoveryPrograms[item.attributes.productId].programType)).map(item => discoveryPrograms[item.attributes.productId]) : [];
  };
  const filterByProgramTypesStandardLP = (programTypes: any, index: number) => {
    return programs && discoveryPrograms && programs[index] ? programs[index].filter(item => programTypes.includes(discoveryPrograms[item.attributes.productId].programType)) : [];
  };
  const mentorShipPrograms = selectedTabIndex === -1 ? [] : filterByProgramTypes(mentorshipTypes, selectedTabIndex);
  const selfPacedPrograms = selectedTabIndex === -1 ? [] : filterByProgramTypes(selfPacedTypes, selectedTabIndex);
  const mentorshipStandardLps = selectedTabIndex === -1 ? [] : filterByProgramTypesStandardLP(mentorshipTypes, selectedTabIndex);
  const selfPacedStandardLps = selectedTabIndex === -1 ? [] : filterByProgramTypesStandardLP(selfPacedTypes, selectedTabIndex);
  return <div className="section__programs" id="section--programs" data-sentry-component="SectionPrograms" data-sentry-source-file="SectionPrograms.tsx">
      <div className="section__programs-container ">
        <div className="section__programs-header section__container--small">
          <p>Explore Your Possibilities</p>
          <h2>Your Industry, Your Path</h2>
          <div className="section__programs-tabs">
            {industries?.map((industry: Industry) => {
            if (programs && discoveryPrograms && filterByProgramTypes(mentorshipTypes, industry.id).length + filterByProgramTypes(selfPacedTypes, industry.id).length > 0) {
              if (!firstFilterSet && selectedTabIndex === -1) {
                setSelectedTabIndex(industry.id);
                firstFilterSet = true;
              }
              return <button key={industry.id} className={`section__programs-tab ${selectedTabIndex === industry.id ? "active" : ""}`} onClick={() => {
                setSelectedTabIndex(industry.id);
              }}>
                    {industry.attributes.name}
                  </button>;
            }
          })}
          </div>
        </div>
        {mentorShipPrograms.length > 0 && <SliderMentorship isHomePage programs={mentorshipStandardLps} subCategories={mentorShipPrograms} industryName={industries?.find(industry => industry.id === selectedTabIndex)?.attributes.name} industryId={industries?.find(industry => industry.id === selectedTabIndex)?.id} />}
        {selfPacedPrograms.length > 0 &&
      // <SliderWorkshop
      //   programs={selfPacedStandardLps}
      //   subCategories={selfPacedPrograms}
      //   industryName={industries?.find(industry => industry.id === selectedTabIndex)?.attributes.name}
      //   industryId={industries?.find(industry => industry.id === selectedTabIndex)?.id}
      // />
      <SliderSelfPaced isHomePage programs={selfPacedStandardLps} subCategories={selfPacedPrograms} industryName={industries?.find(industry => industry.id === selectedTabIndex)?.attributes.name} industryId={industries?.find(industry => industry.id === selectedTabIndex)?.id} />}
      </div>
    </div>;
}
export default SectionPrograms;